<template>
    <div class="zbStudentList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">

                <el-form-item label="学年学期" >
                    <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考试状态">
                    <el-select v-model="searchForm.state" filterable placeholder="请选择">
<!--                        <el-option :key="null" label="全部" value=""></el-option>-->
                        <el-option :key="2" label="已结束" :value="2"></el-option>
                        <el-option :key="99" label="已归档" :value="99"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称"  @keyup.enter.native='handleSearch'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='handleSearch'>查询</el-button>
                    <el-button type="primary" icon='edit' size="mini" :disabled="(selections && selections.length==0) || false" @click='updateArchiveState(99)'>归档</el-button>
                </el-form-item>


            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" @selection-change="selectionChange" style="width: 100%" align='center'>
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="id" label="考试号" align='center'  width="80"></el-table-column>
                <el-table-column prop="name" label="考试名称" align='center' ></el-table-column>
                <el-table-column prop="semester" label="学年学期" width="200" align='center' ></el-table-column>
                <el-table-column prop="state" label="状态" align='center' :formatter="formatterState"></el-table-column>
                <el-table-column prop="nick_name" label="阅卷组长" align='center' ></el-table-column>
                <el-table-column prop="user_name" label="组长账号" align='center' ></el-table-column>
                <el-table-column prop="scan_count" label="扫描数量" align='center'  width="80"></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" >
                    <template slot-scope='{row}'>
                        <el-button type="danger" v-if="row.state== 99" icon='edit' size="mini" @click='archiveSelectionData(row, 2)'>不归档</el-button>
                        <el-button type="primary" v-else icon='edit' size="mini" @click='archiveSelectionData(row, 99)'>归档</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";

    export default {
        name: 'zbStudentList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading: false, isShow:false, licenceList: [], acadyearTermList: [], selections: [],
                selZbStudent: null, selZbStudentDtl: null,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, licenceId: null, semester: null, state: 2, searchText: null},
            }
        },
        components:{
             Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.searchForm.licenceId = this.userInfo.licenceId;
            this.loadAcadyearTermList();
            this.loadExamList();
	   },
        methods: {
            handleSearch(){
                this.searchForm.pageNo = 1;
                this.loadExamList();
            },

            loadExamList(){
                if(this.searchForm.licenceId == null) return ;

                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), semester: mUtils.searchText(this.searchForm.semester),
                    state: this.searchForm.state, containRules: "Y", containZgCount: "Y", containKgCount: "Y",
                    licenceId: this.userInfo.licenceId, orderBy: 'e.id desc'};
                let param = { controllerName: 'akExam', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+2); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            formatterState(row, column, cellValue, index) {

                if(cellValue == 0) return '新建';
                if(cellValue == 1) return '阅卷中';
                if(cellValue == 2) return '已结束';
                if(cellValue == 99) return '已归档';
                return cellValue;

            },

            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadExamList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadExamList()
            },
            selectionChange(val) {
                this.selections = val;
            },
            archiveSelectionData(row, status){
                let that = this;
                this.$confirm("确认将此试卷归档？", '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    that.loading = true;
                    let p = {examIds: [row.id], state: status }
                    let param = { controllerName: 'akExam', methodName: '/batchUpdateState', param: p};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '归档成功!'});
                            that.handleSearch();
                        }else{
                            that.$message({ type: 'success', message: '归档失败!'});
                        }
                        that.loading = false;
                    });
                }).catch((err) => {
                    console.log("err", err);
                    that.loading = false;
                });
            },
            updateArchiveState(status){
                let that = this;
                if(that.selections && that.selections.length > 0){
                    this.$confirm("确认将选中试卷归档？", '提示', {
                        confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                    }).then(() => {
                        that.loading = true;
                        let examIds = [];
                        for (let i=0; i<that.selections.length; i++){
                            let exam = that.selections[i];
                            examIds.push(exam.id);
                        }
                        let p = {examIds: examIds, state: status }
                        let param = { controllerName: 'akExam', methodName: '/batchUpdateState', param: p};
                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            if (code == 0) {
                                that.$message({ type: 'success', message: '归档成功!'});
                                that.handleSearch();
                            }else{
                                that.$message({ type: 'success', message: '归档失败!'});
                            }
                            that.loading = false;
                        });
                    }).catch((err) => {
                        console.log("err", err);
                        that.loading = false;
                    });
                }
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


